import React from "react"
import { Link } from "gatsby"
import { Contact, SectionTitle, Button } from "@hemalr/react-blocks"
import styled from "styled-components"
import {
  mapLink,
  address,
  mapEmbed,
  email,
  phone,
  primaryColor,
} from "../components/site-config"
import Subtitle from "../components/subtitle"
import Layout from "../components/layout"
import SEO from "../components/seo"

const StyledDiv = styled.div`
  display: grid;
  grid-gap: 3rem;
  justify-content: center;
  justify-items: center;
  padding: 0 2rem;
  font-size: 1rem;
`
const ContactPage = () => (
  <Layout>
    <SEO title="Contact Everything Accounting" />
    <StyledDiv>
      <Contact
        title={
          <SectionTitle
            text="Contact"
            color={primaryColor}
            level="h1"
            size="3rem"
          />
        }
        subTitle={
          <Subtitle text="Don't be afraid to reach out. You + us = awesome." />
        }
        mapLink={mapLink}
        address={address}
        mapEmbed={mapEmbed}
        email={email}
        phone={phone}
        minColumnWidth="17rem"
        roundedBtn
      />
      <Link to="/book">
        <Button
          text="Or book a time to see us"
          size="1.5rem"
          color={primaryColor}
          rounded
        />
      </Link>
    </StyledDiv>
  </Layout>
)

export default ContactPage
